import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const Versprechen = ({ data }) => {
  return (
    <Layout>
      <Seo title="jo kalenberg" />
      <main class="py-6 px-4 sm:p-6 md:py-10 md:px-8 start mt-12">
        <div class="bg-gray-50 text-gray-600 px-6 lg:px-8 pt-12 pb-20">
          <div class="max-w-screen-xl mx-auto grid grid-cols-2 gap-8 lg:gap-24 md:items-center">
            <div class="col-span-2 md:col-span-1 order-2 md:order-1">
              <h2 class="text-2xl sm:text-3xl  lg:text-4xl text-gray-700 font-medium mb-6">
                Impressum
              </h2>

              <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
              <p>
                Josef Kalenberg
                <br />
                Medienwerkstatt-Niederrhein
                <br />
                Am K&ouml;nigshof 47
                <br />
                47807 krefeld
              </p>

              <h2>Kontakt</h2>
              <p>
                Telefon: 017642263017
                <br />
                E-Mail: jo.kalenberg@web.de
              </p>

              <h2>Umsatzsteuer-ID</h2>
              <p>
                Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
                Umsatzsteuergesetz:
                <br />
                117/5175/0155
              </p>

              <h2>Redaktionell verantwortlich</h2>
              <p>josef kalenberg</p>

              <h2>EU-Streitschlichtung</h2>
              <p>
                Die Europ&auml;ische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit:{" "}
                <a
                  href="https://ec.europa.eu/consumers/odr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/consumers/odr/
                </a>
                .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>

              <h2>
                Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
              </h2>
              <p>
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
              </p>

              <p>
                Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Versprechen
